import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Row, Col, Container, Image, ListGroup, InputGroup, FormControl, Button } from "react-bootstrap"

const NotFoundPage = () => (
  <Layout pageInfo={{ pageName: "404 not found" }}>
    <SEO title="404 not found" />
    <Row >
      <Col>
        <Container className="mt-4 ">
          <h2>NOT FOUND</h2>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
          <p>Why don't you try <Link to="/search">searching</Link>.</p>
          {/* <p>Or chat for a bit with our resident AI <Link to="/chatbot">CRB-5</Link>.</p> */}
          <p>Alternatively you could just go back to the &nbsp;
      <Link to="/" className="link-no-style">
              main page.
      </Link>
          </p>
        </Container>
      </Col>
    </Row>
  </Layout>
)

export default NotFoundPage
